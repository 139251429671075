import React from "react";
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import {
  setDecreaseItemQTY,
  setIncreaseItemQTY,
  setRemoveItemFromCart,
} from "../store/CartSlice";
import { Wrapper } from "../components";
import { proUrl, imgProUrl } from "../utils/baseUrl";
const CartItem = ({
  item: { id, productName, picture, stock, price, cartQuantity },
}) => {
  //const item = { id, productName, picture, stock, price };
  const dispatch = useDispatch();

  const onRemoveItem = () => {
    dispatch(
      setRemoveItemFromCart({
        id,
        productName,
        picture,
        stock,
        price,
        cartQuantity,
      })
    );
  };

  const onIncreaseItemQTY = () => {
    dispatch(
      setIncreaseItemQTY({
        id,
        productName,
        picture,
        stock,
        price,
        cartQuantity,
      })
    );
  };
  const onDecreaseItemQTY = () => {
    dispatch(
      setDecreaseItemQTY({
        id,
        productName,
        picture,
        stock,
        price,
        cartQuantity,
      })
    );
  };

  const NumberFormatter = (value, decimal) => {
    return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
      "en-IN",
      {
        useGrouping: true,
      }
    );
  };
  // bg-gradient-to-b ${color} ${shadow}
  // className={`relative rounded p-3 hover:scale-105 transition-all duration-75 ease-in-out grid items-center`}
  //xxxblur-theme-effect bg-white/80/w-28 h-auto object-fill lg:w-36
  return (
    <>
      <div className="my-4 border-b pb-4 w-full">
        <div className="w-full">
          <div className="w-full flex flex-col gap-2 md:flex-row">
            <img
              src={`${imgProUrl}/thumbnail/${picture}`}
              alt="logo"
              className="object-cover w-full rounded-md md:object-fill lg:w-36"
            />
            <div className="w-full md:ml-8">
              <h1 className="text-lg lg:text-sm">{productName}</h1>
              <div className="my-4 flex justify-between">
                <span>฿{NumberFormatter(price, 2)}</span>
                <span>฿{NumberFormatter(price * cartQuantity, 2)}</span>
              </div>
              <div className="flex justify-between items-center gap-5 w-full">
                <div className="flex items-center justify-center">
                  <button
                    type="button"
                    onClick={onDecreaseItemQTY}
                    className="bg-accent-tertiary rounded w-6 h-6 lg:w-5 lg:h-5 flex items-center justify-center active:scale-90"
                  >
                    <MinusIcon className="w-5 h-5 lg:w-4 lg:h-4 stroke-[2]" />
                  </button>
                  <div className="bg-theme-cart rounded font-medium lg:text-xs w-7 h-6 lg:h-5 lg:w-6 flex items-center justify-center">
                    {cartQuantity}
                  </div>
                  <button
                    type="button"
                    onClick={onIncreaseItemQTY}
                    className="bg-accent-tertiary rounded w-6 h-6 lg:w-5 lg:h-5 flex items-center justify-center active:scale-90"
                  >
                    <PlusIcon className="w-5 h-5 lg:w-4 lg:h-4 text-white stroke-[2]" />
                  </button>
                </div>
                <button
                  type="button"
                  className="bg-red-500 rounded w-6 h-6 lg:w-5 lg:h-5 grid items-center justify-items-center cursor-pointer"
                  onClick={onRemoveItem}
                >
                  <TrashIcon className="w-5 h-5 text-white" />
                </button>
              </div>
            </div>
          </div>
          {/*           <div className="grid items-center gap-5 p-4 md:px-2">
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-lg lg:text-base font-medium">
                ฿{NumberFormatter(price * cartQuantity, 2)}
              </h1>
              <button
                type="button"
                className="bg-red-500 rounded w-6 h-6 lg:w-5 lg:h-5 grid items-center justify-items-center cursor-pointer"
                onClick={onRemoveItem}
              >
                <TrashIcon className="w-5 h-5 text-white" />
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CartItem;
