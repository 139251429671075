import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import { menuUrl, aboutUrl } from "../utils/baseUrl";

import axios from "axios";
/* const data = [
  { id: 1, name: "หน้าหลัก", url: "/" },
  { id: 2, name: "เกี่ยวกับเรา", url: "/about" },
  { id: 3, name: "โครงการบริจาค", url: "/projects" },
  { id: 4, name: "บูชาวัตถุมงคลเสริมดวง", url: "/products" },
  { id: 5, name: "ทำเนียบผู้บริจาค", url: "/donates" },
  { id: 6, name: "ติดต่อเรา", url: "/contact" },
];

const subMenuData = [
  { id: 1, name: "Jordan", doc_count: 11 },
  { id: 2, name: "Sneakers", doc_count: 8 },
  { id: 3, name: "Running shoes", doc_count: 64 },
  { id: 4, name: "Football shoes", doc_count: 107 },
]; */

const Menu = ({ showCatMenu, setShowCatMenu, categories }) => {
  const [menuDatas, setMenuDatas] = useState([]);
  const [subAbouts, setSubAbouts] = useState([]);
  function readData() {
    axios.get(`${menuUrl}read.php`).then(function (response) {
      console.log(response.data);
      setMenuDatas(response.data);
    });
  }
  function readsubaboutmenu(no) {
    axios.get(`${aboutUrl}readsubmenu.php/${no}`).then(function (response) {
      console.log("Sub About Menu: ", response.data);
      setSubAbouts(response.data);
    });
  }
  useEffect(() => {
    readData();
    readsubaboutmenu(2);
  }, []);

  console.log("Menu: ", menuDatas);
  return (
    <ul className="hidden xl:flex items-center gap-8 font-medium">
      {menuDatas?.map((item) => {
        return (
          <React.Fragment key={item.id}>
            {item?.submenu === "1" ? (
              <>
                {item?.id === "2" && (
                  <>
                    <li
                      className="cursor-pointer flex items-center gap-2 relative"
                      onMouseEnter={() => setShowCatMenu(true)}
                      onMouseLeave={() => setShowCatMenu(false)}
                    >
                      {item?.name}
                      <BsChevronDown size={14} />
                      {showCatMenu && (
                        <ul className="absolute top-6 left-0 bg-secondary min-w-[350px] px-1 py-1 shadow-lg  rounded-md">
                          {subAbouts?.map((v, i) => (
                            <Link
                              key={i}
                              to={v.url}
                              onClick={() => setShowCatMenu(false)}
                            >
                              <li className="h-12 flex justify-between items-center px-3 hover:bg-black/[0.03] rounded-md">
                                {v.name}
                              </li>
                            </Link>
                          ))}
                        </ul>
                      )}
                    </li>
                  </>
                )}
              </>
            ) : (
              <li className="cursor-pointer">
                <Link to={item?.url}>{item.name}</Link>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </ul>
  );
};

export default Menu;
