import React from "react";
import { Link } from "react-router-dom";
import { proUrl, imgProUrl, imgPjUrl } from "../utils/baseUrl";

const PjCard = ({ data }) => {
  console.log("Data: ", data);
  //userId,categoryId,projectName,location,detail,beginDate,endDate,total,picture
  const {
    id,
    projectName,
    location,
    detail,
    beginDate,
    endDate,
    total,
    picture,
  } = data;
  const NumberFormatter = (value, decimal) => {
    return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
      "en-IN",
      {
        useGrouping: true,
      }
    );
  };
  //overflow-hidden w-full bg-red-200 rounded-md shadow-lg hover:shadow transition-all duration-300 cursor-pointer
  //p-4 shadow-lg hover:shadow transition-all duration-300 cursor-pointer
  return (
    <>
      <Link
        to={`/project/${id}`}
        className="overflow-hidden relative rounded-md shadow-lg hover:shadow transition-all duration-300 cursor-pointer"
      >
        <img
          src={`${imgPjUrl}/thumbnail/${picture}`}
          alt="logo"
          className="object-cover w-full rounded-md"
        />
        <div className="p-2 w-full bg-zinc-900/50 text-black/[0.5] rounded-md my-2 absolute bottom-0 left-0">
          <h2 className="text-base p-2 m-2 bg-zinc-900/50 text-white">
            {projectName}
          </h2>

          {/*           <div className="flex items-center text-black/[0.5]">
            <p className="mr-2 text-lg font-semibold">
              ฿{NumberFormatter(total, 2)}
            </p>
          </div> */}
        </div>
        {/*         <div className="w-full my-16">
          <div className="shadow w-full">
            <div
              className="bg-teal-500 text-xs leading-none py-1 text-center text-white"
              style={{ width: "45%" }}
            >
              45%
            </div>
          </div>
        </div> */}
      </Link>
    </>
  );
};

export default PjCard;
