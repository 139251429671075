import React, { useState, useEffect } from "react";

import { infoslideUrl, infoslideFileUrl } from "../utils/baseUrl";
import Marquee from "react-fast-marquee";
import axios from "axios";

const InfoslideCard = () => {
  const [infos, setInfos] = useState([]);
  function readSponsors() {
    axios.get(`${infoslideUrl}read.php`).then(function (response) {
      console.log("Info: ", response.data);
      setInfos(response.data);
    });
  }

  useEffect(() => {
    readSponsors();
  }, []);

  return (
    <Marquee
      direction="left"
      speed={100}
      delay={5}
      pauseOnHover={true}
      style={{ backgroundColor: "#FFF" }}
    >
      {infos.map((item, i) => (
        <div
          key={i}
          className="flex items-center bg-white text-black p-2 rounded-md cursor-pointer"
        >
          {/*           {item?.photo ? (
            <>
              <img
                src={`${infoslideFileUrl}/thumbnail/${item?.photo}`}
                alt="logo"
                className="w-[150px] h-auto"
              />
            </>
          ) : (
            <></>
          )} */}

          <div className="p-2 text-base">{item?.title}</div>
        </div>
      ))}
    </Marquee>
  );
};

export default InfoslideCard;
