import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  HeroBanner,
  Wrapper,
  ProductDetailsCarousel,
  CtaSection,
  RelatedProducts,
} from "../components";
import { proUrl, imgProUrl } from "../utils/baseUrl";
import { IoMdHeartEmpty } from "react-icons/io";
import axios from "axios";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import ReactMarkdown from "react-markdown";
import { useSelector, useDispatch } from "react-redux";
import { setAddItemToCart } from "../store/CartSlice";

const ProductDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [gallerys, setGallerys] = useState([]);

  const onAddToCart = (product) => {
    // alert(JSON.stringify(product));

    // console.log({ ...product });
    const { id, productName, picture, stock, price } = product;
    //id, productName, productDetail, price, stock, picture
    /*     const item { id, productName, productDetail, picture, stock, price } =
      myproduct; */
    const item = { id, productName, picture, stock, price };
    // alert(productName);
    console.log(item);
    dispatch(setAddItemToCart(item));
  };

  function readgallery(id) {
    axios.get(`${proUrl}readgallery.php/${id}`).then(function (response) {
      console.log(response.data);
      setGallerys(response.data);
    });
  }

  function readproduct(id) {
    axios.get(`${proUrl}readbyid.php/${id}`).then(function (response) {
      console.log("Product by ID : ", response.data);
      setProduct(response.data);
    });
  }
  console.log("My Product: ", product);
  function readallproduct() {
    axios.get(`${proUrl}read.php`).then(function (response) {
      console.log("Product: ", response.data);
      setProducts(response.data);
    });
  }
  useEffect(() => {
    readgallery(id);
    readproduct(id);
    readallproduct();
  }, []);

  /*   const notify = () => {
    toast.success("Success. Check your cart!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }; */
  const NumberFormatter = (value, decimal) => {
    return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
      "en-US",
      {
        useGrouping: true,
      }
    );
  };
  return (
    <div className="w-full md:py-20">
      {/* <ToastContainer /> */}
      <div>
        <div className="flex flex-col lg:flex-row md:px-10 gap-[50px] lg:gap-[100px]">
          {/* left column start */}
          <div className="w-full md:w-auto flex-[1.5] mx-auto lg:mx-0">
            {gallerys.length > 0 ? (
              <>
                <ProductDetailsCarousel images={gallerys} />
              </>
            ) : (
              <>
                <img
                  src={`${imgProUrl}/${product?.picture}`}
                  alt="logo"
                  className="object-cover w-full rounded-md"
                />
              </>
            )}
            {/* <ProductDetailsCarousel images={p.image.data} /> */}
          </div>
          {/* left column end */}

          {/* right column start */}
          <div className="flex-[1] py-3">
            {/* PRODUCT TITLE */}
            <div className="text-2xl font-semibold mb-2 leading-tight">
              {product?.productName}
            </div>
            {/* const { id, productName, productDetail, price, stock, picture } = data; */}
            {/* PRODUCT SUBTITLE */}
            {/* <div className="text-lg font-semibold mb-5">{p.subtitle}</div> */}

            {/* PRODUCT PRICE */}
            <div className="flex items-center">
              <p className="mr-2 text-lg font-semibold">
                ราคา : {NumberFormatter(product?.price, 2)} บาท
              </p>
              {/*               {p.original_price && (
                <>
                  <p className="text-base  font-medium line-through">
                    &#8377;{p.original_price}
                  </p>
                  <p className="ml-auto text-base font-medium text-green-500">
                    {getDiscountedPricePercentage(p.original_price, p.price)}%
                    off
                  </p>
                </>
              )} */}
            </div>

            {/*             <div className="text-md font-medium text-black/[0.5]">
              incl. of taxes
            </div>
            <div className="text-md font-medium text-black/[0.5] mb-20">
              {`(Also includes all applicable duties)`}
            </div> */}

            {/* PRODUCT SIZE RANGE START */}
            <div className="flex justify-between my-4">
              {/* PRODUCT SIZE RANGE END */}

              {/* ADD TO CART BUTTON START */}
              <button
                className="btn btn-accent"
                onClick={() => onAddToCart(product)}
                /*                     onClick={() => {
                        if (!selectedSize) {
                            setShowError(true);
                            document
                                .getElementById("sizesGrid")
                                .scrollIntoView({
                                    block: "center",
                                    behavior: "smooth",
                                });
                        } else {
                            dispatch(
                                addToCart({
                                    ...product?.data?.[0],
                                    selectedSize,
                                    oneQuantityPrice: p.price,
                                })
                            );
                            notify();
                        }
                    }} */
              >
                หยิบใส่ตระกร้า
              </button>
              {/* ADD TO CART BUTTON END */}

              {/* WHISHLIST BUTTON START */}
              <button className="btn btn-sky">
                รายการโปรด
                <IoMdHeartEmpty size={20} />
              </button>
              {/* WHISHLIST BUTTON END */}
            </div>
            <div>
              <div className="text-lg font-bold mb-5">รายละเอียดสินค้า</div>
              {/*              <div className="markdown text-md mb-5">
                <ReactMarkdown>{product?.productDetail}</ReactMarkdown>
              </div> */}
              <p
                className="markdown text-md mb-5"
                dangerouslySetInnerHTML={{ __html: product?.productDetail }}
              ></p>
            </div>
          </div>
          {/* right column end */}
        </div>

        {/* <RelatedProducts products={products} title={"สินค้าแนะนำ"} /> */}
      </div>
    </div>
  );
};

export default ProductDetail;
