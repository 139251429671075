import React, { useContext, useState, useEffect } from "react";
import { AiFillPlayCircle, AiOutlineHeart } from "react-icons/ai";
import { BsPlayCircle, BsThreeDots } from "react-icons/bs";
import { donateUrl, imgbillUrl } from "../utils/baseUrl";
import axios from "axios";

const Card_sm = ({ show }) => {
  const [donates, setDonate] = useState([]);
  function readdonate() {
    axios.get(`${donateUrl}readAllProject.php`).then(function (response) {
      console.log(response.data);
      setDonate(response.data);
    });
  }

  useEffect(() => {
    readdonate();
  }, []);
  console.log(donates); //
  return (
    <>
      {donates.map((item, i) => (
        <div
          className="relative shadow-md hover:shadow my-4 flex bg-theme-cart p-2 rounded-md transition ease-in-out cursor-pointer"
          key={i}
        >
          <div className="img relative h-16 w-16 mr-5">
            <img
              src={`${imgbillUrl}${item?.profile}`}
              alt={item?.fullname}
              className="w-full h-full object-cover rounded-full"
            />
            {/*               <div className="overlay icon absolute top-3 left-2 text-white ">
                <BsPlayCircle size={45} className="show" />
                <AiFillPlayCircle
                  size={50}
                  className="hide absolute -top-1 -left-1"
                />
              </div> */}
          </div>
          {/*             <div className="overlay absolute bottom-0 right-0 text-secondary">
              <div className="flex p-3">
                <AiOutlineHeart size={22} className="mx-3" />
                <BsThreeDots size={22} />
              </div>
            </div> */}
          <div className="text">
            <h3 className="text-base font-bold">{item?.fullname}</h3>
            <span className="text-base font-semibold">{item?.total} บาท</span>
          </div>
        </div>
      ))}
    </>
  );
};

export default Card_sm;
