import React from "react";
import { Link } from "react-router-dom";
import { proUrl, imgProUrl, imgPjUrl } from "../utils/baseUrl";

const ProjectCard = ({ data }) => {
  console.log("Data: ", data);
  //userId,categoryId,projectName,location,detail,beginDate,endDate,total,picture
  const {
    id,
    projectName,
    location,
    detail,
    beginDate,
    endDate,
    total,
    picture,
  } = data;
  const NumberFormatter = (value, decimal) => {
    return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
      "en-IN",
      {
        useGrouping: true,
      }
    );
  };
  //transform overflow-hidden bg-white duration-200 hover:scale-105 cursor-pointer
  return (
    <>
      <Link
        to={`/project/${id}`}
        className="overflow-hidden relative rounded-md shadow-lg hover:shadow transition-all duration-300 cursor-pointer"
      >
        <img
          src={`${imgPjUrl}/thumbnail/${picture}`}
          alt="logo"
          className="object-cover w-full rounded-md"
        />
        <div className="p-2 w-full bg-zinc-900/50 text-black/[0.5] rounded-md my-2 absolute bottom-0 left-0">
          <h2 className="text-base font-medium  text-white">{projectName}</h2>
          {/*           <div className="flex items-center text-black/[0.5]">
            <p className="mr-2 text-lg font-semibold">
              ฿{NumberFormatter(total, 2)}
            </p>
          </div> */}
        </div>
      </Link>
    </>
  );
};

export default ProjectCard;
