import React, { useState, useEffect } from "react";
import {
  HeroBanner,
  ProductCard,
  ProjectCard,
  Wrapper,
  CtaSection,
  RelatedProject,
  Title,
  Card_sm,
} from "../components";
import { proUrl, donateUrl, catUrl, pjUrl } from "../utils/baseUrl";
import axios from "axios";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  function readProject() {
    axios.get(`${pjUrl}read.php`).then(function (response) {
      console.log(response.data);
      setProjects(response.data);
    });
  }

  useEffect(() => {
    readProject();
  }, []);
  return (
    <>
      <div className="mt-[50px] w-full md:mt-[100px]">
        <main className="md:flex w-full md:justify-between">
          <div className="content w-full md:w-[73%]">
            <Title title="โครงการบริจาค" />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-14 md:px-0">
              {projects?.map((project, i) => (
                <ProjectCard key={i} data={project} />
              ))}
            </div>
          </div>
          {/* border-2 border-solid border-gray-100 rounded-xl */}
          <div className="md:w-[25%] md:px-5">
            <Title title="ทำเนียบผู้บริจาค" />
            <Card_sm />
          </div>
        </main>
      </div>
    </>
  );
};

export default Projects;
