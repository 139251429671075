import React, { useState, useEffect } from "react";
import {
  HeroBanner,
  ProductCard,
  Wrapper,
  CtaSection,
  RelatedProject,
  ProjectHome,
  Title,
  Card_sm,
  RelatedSponsor,
  InfoslideCard,
} from "../components";
import {
  proUrl,
  donateUrl,
  catUrl,
  pjUrl,
  sponsorUrl,
  sponsorFileUrl,
} from "../utils/baseUrl";

import axios from "axios";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [catData, setCatData] = useState([]);
  const [donates, setDonates] = useState([]);
  const [sponsors, setSponsors] = useState([]);

  function readDonate() {
    axios.get(`${donateUrl}read.php`).then(function (response) {
      console.log(response.data);
      setDonates(response.data);
    });
  }
  function readProject() {
    axios.get(`${pjUrl}read.php`).then(function (response) {
      console.log(response.data);
      setProjects(response.data);
    });
  }
  function readSponsors() {
    axios.get(`${sponsorUrl}read.php`).then(function (response) {
      console.log("Sponse: ", response.data);
      setSponsors(response.data);
    });
  }

  function readcat() {
    axios.get(`${catUrl}read.php`).then(function (response) {
      console.log(response.data);
      setCatData(response.data);
    });
  }
  function readproduct() {
    axios.get(`${proUrl}read.php`).then(function (response) {
      console.log("Product: ", response.data);
      setProducts(response.data);
    });
  }

  useEffect(() => {
    readcat();
    readproduct();
    // readDonate();
    readProject();
    readSponsors();
  }, []);

  console.log("Pro: ", products);
  return (
    <>
      <HeroBanner />
      <div className="container">
        {/* <CtaSection /> */}
        <div className="my-[10px]">
          <InfoslideCard />
        </div>
        {/* heading and paragaph end */}
        <main className="md:flex w-full md:justify-between">
          <div className="w-full md:w-[73%]">
            {/* <ProjectHome projects={projects} title={"โครงการบริจาค"} /> */}
            <RelatedProject projects={projects} title={"โครงการบริจาค"} />
            {/* products grid start*/}
            <div className="text-2xl font-bold mt-[50px] md:mt-[100px] my-5">
              <Title title="บูชาวัตถุมงคลเสริมดวง" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-14 md:px-0">
              {products?.map((product, i) => (
                <ProductCard key={i} data={product} />
              ))}
            </div>
            {/* <Title title="บูชาวัตถุมงคลเสริมดวง" /> */}

            {/*             <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-14 md:px-0">
              {products?.map((product, i) => (
                <ProductCard key={i} data={product} />
              ))}
            </div> */}
            <div className="mb-16">
              <RelatedSponsor sponsors={sponsors} title={"ผู้สนับสนุน"} />
            </div>
          </div>
          {/* border-2 border-solid border-gray-100 rounded-xl */}
          <div className="md:w-[25%] md:px-5 mb-16">
            <Title title="ทำเนียบผู้บริจาค" />
            {/*             {recommand.slice(0, 5).map((item, i) => (
          <div className='mb-3'>
            <Card_sm cover={item.cover} name={item.name} tag={item.tag} i={i} />
          </div>
        ))} */}
            <Card_sm />
          </div>
        </main>

        {/* products grid end */}
      </div>
    </>
  );
};

export default Home;
