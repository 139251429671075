import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCartItems,
  selectCartState,
  selectTotalAmount,
  selectTotalQTY,
  setClearCartItems,
  setCloseCart,
  setGetTotals,
} from "../store/CartSlice";
import { Link, useNavigate } from "react-router-dom";
import emptybag from "../assets/emptybag.png";
import { CartCount, CartEmpty, CartItem, Wrapper } from "../components";
// import { Link} from "react-router-dom";
const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ifCartState = useSelector(selectCartState);
  const cartItems = useSelector(selectCartItems);
  const totalAmount = useSelector(selectTotalAmount);
  const totalQTY = useSelector(selectTotalQTY);

  // console.log(cartItems)

  useEffect(() => {
    dispatch(setGetTotals());
  }, [cartItems, dispatch]);

  const onCartToggle = () => {
    dispatch(
      setCloseCart({
        cartState: false,
      })
    );
  };

  const onClearCartItems = () => {
    dispatch(setClearCartItems());
  };

  const onCheckout = () => {
    navigate("/confirmcheckout");
  };

  const NumberFormatter = (value, decimal) => {
    return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
      "en-IN",
      {
        useGrouping: true,
      }
    );
  };

  console.log(cartItems);
  return (
    <div className="w-full md:py-20">
      <div>
        {cartItems.length > 0 && (
          <>
            {/* HEADING AND PARAGRAPH START */}
            <div className="text-center max-w-[800px] mx-auto mt-8 md:mt-0">
              <div className="text-[28px] mb-4 md:text-[34px] font-semibold ">
                ตระกร้าสินค้า
              </div>
            </div>
            {/* HEADING AND PARAGRAPH END */}

            {/* CART CONTENT START */}
            <div className="flex flex-col lg:flex-row gap-5 pb-10 md:py-10">
              {/* CART ITEMS START */}
              <div className="flex-[2]">
                {/* <div className="text-lg font-bold">Cart Items</div> */}
                {cartItems.map((item, i) => (
                  <CartItem key={i} item={item} />
                ))}
              </div>
              {/* CART ITEMS END */}

              {/* SUMMARY START */}
              <div className="flex-[1]">
                <div className="text-lg font-bold">สรุปคำสั่งซื้อ</div>

                <div className="my-5 bg-black/[0.05] rounded-xl">
                  <div className="flex justify-between">
                    <div className="uppercase text-md md:text-lg font-medium">
                      ยอดสั่งซื้อ
                    </div>
                    <div className="text-md md:text-lg font-medium">
                      {/* &#8377;{subTotal} */}
                      {totalAmount != null
                        ? NumberFormatter(totalAmount, 2)
                        : 0}
                    </div>
                  </div>
                  <div className="text-sm md:text-md py-5 border-t mt-5"></div>
                </div>

                {/* BUTTON START w-full py-4 rounded-full bg-black text-white text-lg font-medium transition-transform active:scale-95 mb-3 hover:opacity-75 flex items-center gap-2 justify-center*/}
                <button
                  className="btn btn-accent w-full"
                  // onClick={handlePayment}
                  onClick={() => onCheckout()}
                >
                  ยืนยันคำสั่งซื้อ
                  {/* {loading && <img src="/spinner.svg" />} */}
                </button>
                {/* BUTTON END */}
              </div>
              {/* SUMMARY END */}
            </div>
            {/* CART CONTENT END */}
          </>
        )}

        {/* This is empty screen */}
        {cartItems.length < 1 && (
          <div className="flex-[2] flex flex-col items-center pb-[50px] mt-8 md:-mt-14">
            {/*             <Image
              src="/empty-cart.jpg"
              width={300}
              height={300}
              className="w-[300px] md:w-[400px]"
            /> */}
            <img
              src={emptybag}
              alt="emptybag/img"
              // className="w-40 lg:w-36 sm:w-28 h-auto object-fill transition-all duration-300 hover:scale-110"
              className="w-[300px] md:w-[400px]"
            />
            <span className="text-xl font-bold mt-4">Your cart is empty</span>
            <span className="text-center mt-4">
              Looks like you have not added anything in your cart.
              <br />
              Go ahead and explore top categories.
            </span>
            <Link to="/" className="btn btn-accent mt-8">
              Continue Shopping
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
