import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import { menuUrl, aboutUrl } from "../utils/baseUrl";
import axios from "axios";
/* const data = [
  { id: 1, name: "หน้าหลัก", url: "/" },
  { id: 2, name: "เกี่ยวกับเรา", url: "/about" },
  { id: 3, name: "โครงการบริจาค", url: "/projects" },
  { id: 4, name: "บูชาวัตถุมงคลเสริมดวง", url: "/products" },
  { id: 5, name: "ทำเนียบผู้บริจาค", url: "/donates" },
  { id: 6, name: "ติดต่อเรา", url: "/contact" },
];

const subMenuData = [
  { id: 1, name: "Jordan", doc_count: 11 },
  { id: 2, name: "Sneakers", doc_count: 8 },
  { id: 3, name: "Running shoes", doc_count: 64 },
  { id: 4, name: "Football shoes", doc_count: 107 },
]; */

const MenuMobile = ({
  showCatMenu,
  setShowCatMenu,
  setMobileMenu,
  categories,
}) => {
  const [menuDatas, setMenuDatas] = useState([]);
  const [subAbouts, setSubAbouts] = useState([]);
  function readData() {
    axios.get(`${menuUrl}read.php`).then(function (response) {
      console.log(response.data);
      setMenuDatas(response.data);
    });
  }
  function readsubaboutmenu(no) {
    axios.get(`${aboutUrl}readsubmenu.php/${no}`).then(function (response) {
      console.log("Sub About Menu: ", response.data);
      setSubAbouts(response.data);
    });
  }
  useEffect(() => {
    readData();
    readsubaboutmenu(2);
  }, []);

  console.log("Menu: ", menuDatas); //
  return (
    <ul className="z-[9999] bg-primary  flex flex-col xl:hidden font-bold absolute top-[50px] left-0 w-full h-[calc(100vh-50px)]">
      {menuDatas?.map((item) => {
        return (
          <React.Fragment key={item.id}>
            {item?.submenu === "1" ? (
              <>
                {item?.id === "2" && (
                  <>
                    <li
                      className="cursor-pointer py-4 px-5 flex flex-col relative"
                      onClick={() => setShowCatMenu(!showCatMenu)}
                    >
                      <div className="flex justify-between items-center">
                        {item.name}
                        <BsChevronDown size={14} />
                      </div>
                      {showCatMenu && (
                        <ul className="bg-secondary shadow-lg  rounded-md -mx-5 mt-4 -mb-4">
                          {subAbouts?.map((v, i) => (
                            <Link
                              key={i}
                              to={v.url}
                              onClick={() => {
                                setShowCatMenu(false);
                                setMobileMenu(false);
                              }}
                            >
                              <li className="py-4 px-8">{v.name}</li>
                            </Link>
                          ))}
                        </ul>
                      )}
                    </li>
                  </>
                )}
              </>
            ) : (
              <li className="py-4 px-5">
                <Link to={item?.url} onClick={() => setMobileMenu(false)}>
                  {item.name}
                </Link>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </ul>
  );
};

export default MenuMobile;
