import React from "react";
import { HeroBanner, Header, Footer } from "../components";

const MainLayout = ({ children, className }) => {
  return (
    <div className="">
      <Header />
      {/* <HeroBanner /> */}
      <main
        className={`w-full max-w-[1280px] px-5 md:px-10 mx-auto ${
          className || ""
        }`}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
