import React, { useContext, useState, useEffect } from "react";
import { AiFillPlayCircle, AiOutlineHeart } from "react-icons/ai";
import { BsPlayCircle, BsThreeDots } from "react-icons/bs";
import { donateUrl, imgbillUrl } from "../utils/baseUrl";
import axios from "axios";
import { Title } from "../components";
const Donates = () => {
  const [donates, setDonate] = useState([]);
  function readdonate() {
    axios.get(`${donateUrl}readAllProject.php`).then(function (response) {
      console.log(response.data);
      setDonate(response.data);
    });
  }

  useEffect(() => {
    readdonate();
  }, []);
  console.log(donates); //    {donates.slice(0,2)}
  return (
    <div>
      <section className="mt-8 mb-8 md:my-20 container mx-auto">
        <div className="text-center text-[28px] md:text-[34px] mb-8 font-semibold leading-tight">
          <Title title="ทำเนียบผู้บริจาค" />
        </div>
        {donates?.length > 0 ? (
          <>
            <div className="grid w-full grid-cols-1 md:grid-cols-3  gap-5">
              {donates.slice(0, 3).map((item, i) => (
                <div
                  className="relative w-full bg-topdonate w-[370px] h-[200px] bg-contain shadow-md hover:shadow flex flex-col items-center justify-center rounded-lg"
                  key={i}
                >
                  <div className="flex flex-col items-center justify-center">
                    <div className="relative  h-[125px] w-[125px]">
                      <img
                        src={`${imgbillUrl}${item?.profile}`}
                        alt=""
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                    <div className="text-center text-white mt-4">
                      <h3 className="text-md font-semibold">
                        {item?.fullname}
                      </h3>
                      <span className="">{item?.total} บาท</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-2">
              {donates.slice(3, 54).map((item, i) => (
                <div
                  className="px-2 w-full mx-auto bg-middledonate w-[200px] h-[200px] bg-cover shadow-md hover:shadow my-4 flex flex-col items-center justify-center rounded-lg"
                  key={i}
                >
                  <div className="flex w-full flex-col items-center justify-center">
                    <div className="img relative h-[125px] w-[125px]">
                      <img
                        src={`${imgbillUrl}${item?.profile}`}
                        alt="cover"
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                    <div className="text-center w-full">
                      <h3 className="text-md text-gray-700 font-semibold">
                        {item?.fullname}
                      </h3>
                      <span className="text-gray-700">{item?.total} บาท</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="text-center w-full">
              <h1 className="text-xl font-semibold">***ไม่มีผู้บริจาค***</h1>
            </div>
          </>
        )}
      </section>
    </div>
    /*     <div className="w-full md:py-20">
      <div>
        <div className="text-center max-w-[800px] mx-auto mt-8 md:mt-0">
          <div className="text-[28px] md:text-[34px] mb-5 font-semibold leading-tight">
            บันทึกคำสั่งซื้อ
          </div>
        </div>
      </div>
    </div> */
  );
};

export default Donates;
