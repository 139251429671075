// const url = "https://dashboardgiftlikes.com/mobileApi/";
//const url = "http://localhost/donate_api/";
// const url = "http://192.168.1.121:8080/donate_api/";
const url = "https://พระอินทร์ทรงช้างเอราวัณ.com/donate_api/";
//192.168.1.111,192.168.1.116,192.168.1.110
//``
// 192.168.1.137
// const url = "http://192.168.1.137:8080/donate_api/";
// _Black Gold Elegant Seminar certificate Landscape
export const pjyoutubeUrl = `${url}pjyoutube/`;
export const bankUrl = `${url}bank/`;
export const catUrl = `${url}category/`;
export const companyUrl = `${url}company/`;
export const donateUrl = `${url}donate/`;
export const memberUrl = `${url}member/`;
export const membersUrl = `${url}members/`;
export const ordersUrl = `${url}orders/`;
export const userUrl = `${url}users/`;
export const pjUrl = `${url}project/`;
export const subpjUrl = `${url}subproject/`;
export const proUrl = `${url}products/`;
export const blogUrl = `${url}blog/`;
export const soundUrl = `${url}sound/`;
export const videoUrl = `${url}video/`;
export const infoslideUrl = `${url}infoslide/`;
export const menuUrl = `${url}menu/`;
export const sponsorUrl = `${url}sponsor/`;
export const pgalleryUrl = `${url}pgallery/`;
export const pjgalleryUrl = `${url}pjgallery/`;
export const aboutUrl = `${url}about/`;
export const contactUrl = `${url}contact/`;
export const slideUrl = `${url}slider/`;
export const statusUrl = `${url}status/`;
export const aboutCompanyUrl = `${url}aboutcompany/`;
//$target_dir = "../assets/imgs/";
export const imgCompanyUrl = `${url}assets/imgs/`;
export const imgPjUrl = `${url}assets/pjphoto/`;
export const imgProUrl = `${url}assets/pphoto/`;
export const imgBlogUrl = `${url}assets/blog/`;
export const soundFileUrl = `${url}assets/sound/`;
export const imgUrl = `${url}assets/imgs/`;
export const imgbillUrl = `${url}assets/bill/`;
export const videoFileUrl = `${url}assets/video/`; //assets\video
export const sponsorFileUrl = `${url}assets/sponsor/`; //assets\video
export const infoslideFileUrl = `${url}assets/infoslide/`; //assets\video
export const imgBankUrl = `${url}assets/bank/`;
