import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
// import { donateUrl, pjUrl, soundUrl, soundFileUrl } from "../utils/baseUrl";
// import { proUrl, imgProUrl } from "../utils/baseUrl";
import {
  proUrl,
  donateUrl,
  catUrl,
  pjUrl,
  membersUrl,
  ordersUrl,
} from "../utils/baseUrl";
import ReactAudioPlayer from "react-audio-player";
import axios from "axios";
// import { BsPlus, BsEyeFill } from "react-icons/bs";
import { Title } from "../components";
import { toPng } from "html-to-image";

const PaidBill = () => {
  const { id } = useParams();
  // const [project, setProject] = useState(null);
  const [orderData, setOrderdata] = useState({});
  const elementRef = useRef(null);

  function readOrder(id) {
    axios.get(`${ordersUrl}readordersbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setOrderdata(response.data);
    });
  }

  const htmlToImageConvert = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "certificate.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    readOrder(id);
  }, []);

  console.log(orderData);

  return (
    <div className="w-full md:py-20">
      <div className="mb-16">
        {/* HEADING AND PARAGRAPH START text-[28px] md:text-[34px] */}
        <div className="text-center container mx-auto mt-8 md:mt-0">
          {/*           <div className="w-full md:w-auto flex-[1.5] max-w-[500px] lg:max-w-full mx-auto lg:mx-0">
        <Title title={project?.projectName} />
      </div> */}
          <div
            ref={elementRef}
            className="w-full col-span-1 min-h-[550px] lg:h-[800px] my-12 m-auto overflow-scroll"
          >
            <div className="relative w-full h-full">
              <img
                className="object-cover h-full w-full"
                src={require("../assets/imgs/certificate_1.jpg")}
                alt=""
              />
              <div className="absolute lg:top-[235px] lg:text-[30px] lg:left-[18px] md:top-[160px] md:left-[12px] md:text-[20px] w-full font-normal text-[11px] top-[78px] left-[5px] z-5 text-[#D3B64E]">
                {orderData?.firstname} {orderData?.lastname}
              </div>
              {/* text-[8px] font-thin top-[92px] pl-[110px] pt-[6px] md:pt-6 md:font-normal flex justify-center w-full md:text-[22px] md:pl-[350px] md:top-[307px]   */}
              <div className="absolute lg:top-[349px] lg:left-[285px] lg:text-[25px] xl:top-[347px] lg:left-[310px] md:top-[233px] md:text-[18px] md:left-[198px]  text-[8px] w-full top-[110px] left-[98px] z-5 text-[#D3B64E]">
                {orderData?.total}
              </div>
            </div>
          </div>
          <div className="flex justify-center md:justify-end my-8">
            <button className="btn btn-accent" onClick={htmlToImageConvert}>
              Download Certificate
            </button>
          </div>
          {/* text-[10px] font-thin  top-16 pt-2 md:pt-4 md:font-normal flex justify-center w-full md:text-[32px] md:top-[220px]  z-5  */}

          {/* ---------  */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/*             <p
          className="text-sm pt-2"
          dangerouslySetInnerHTML={{ __html: project?.detail }}
        ></p> */}
            {/*         <h2 className="text-xl font-bold dark:text-white">
          โครงการบริจาค {project?.projectName}#{id}
        </h2> */}
            <div>
              <h2>
                <strong>ลูกค้า</strong> {orderData?.firstname}{" "}
                {orderData?.lastname}
              </h2>
              <h2>
                <strong>ยอดเงินสุทธิ {orderData?.total} บาท</strong>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaidBill;
