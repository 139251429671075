import React from "react";

const DonateByTax = () => {
  return (
    <div className="w-full md:py-20">
      <div>
        {/* HEADING AND PARAGRAPH START */}
        <div className="text-center max-w-[800px] mx-auto mt-8 md:mt-0">
          <div className="text-[28px] md:text-[34px] mb-5 font-semibold leading-tight">
            บันทึกคำสั่งซื้อ
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonateByTax;
