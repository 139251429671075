import React, { useState, useEffect } from "react";
import { aboutUrl, contactUrl, slideUrl, imgUrl } from "../utils/baseUrl";

import axios from "axios";
import { FaArrowRight } from "react-icons/fa";

const ContactUs = () => {
  const [contactData, setContactData] = useState([]);
  function readcontact() {
    axios.get(`${contactUrl}readcontact.php`).then(function (response) {
      console.log(response.data);
      setContactData(response.data);
    });
  }

  useEffect(() => {
    readcontact();
  }, []);
  return (
    <div className="md:pb-[8rem]">
      {contactData?.map((item, i) => (
        <div className="flex flex-col my-10" key={i}>
          <div className="sm:text-3xl text-xl font-bold mb-8">
            {item?.title}
          </div>
          <div className="md:row-start-1 shadow-lg hover:shadow transition-all duration-300 cursor-pointer">
            <img
              className="object-cover w-full rounded-md"
              src={`${imgUrl}${item?.photo}`}
              alt=""
            />
          </div>
          <div className="flex flex-col justify-center items-start gap-6 mt-8">
            {/*             <h3 className="sm:text-2xl text-xl font-semibold mt-8">
              {item?.subtitle}
            </h3> */}
            <p
              className="text-sm font-normal opacity-70"
              dangerouslySetInnerHTML={{ __html: item?.detail }}
            ></p>
            {/*           <div className="btn">
          <a href="" className="text-white text-[0.85rem]">
            Explore more
          </a>
          <FaArrowRight className="text-white" />
        </div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactUs;
