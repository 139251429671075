import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCartItems,
  selectCartState,
  selectTotalAmount,
  selectTotalQTY,
  setClearCartItems,
  setCloseCart,
  setGetTotals,
} from "../store/CartSlice";
import { Link, useNavigate } from "react-router-dom";
import emptybag from "../assets/empty-cart.jpg";
import { CartCount, CartEmpty, CartItem, Wrapper } from "../components";
import {
  proUrl,
  donateUrl,
  catUrl,
  pjUrl,
  membersUrl,
  ordersUrl,
} from "../utils/baseUrl";
import axios from "axios";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const ConfirmCheckout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ifCartState = useSelector(selectCartState);
  const cartItems = useSelector(selectCartItems);
  const totalAmount = useSelector(selectTotalAmount);
  const totalQTY = useSelector(selectTotalQTY);
  const [auth, setAuth] = useState(null);
  const [email, setEmail] = useState(null);
  const [userId, setUserId] = useState("");
  const [note, setNote] = useState("");
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  // form validation rules
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("กรุณาป้อนชื่อ"),
    lastName: Yup.string().required("กรุณาป้อนสกุล"),
    mobile: Yup.string()
      .required("กรุณาป้อนโทรศัพท์")
      .matches(phoneRegExp, "กรุณาป้อนโทรศัพท์ไม่ถูกต้อง"),
    email: Yup.string().required("กรุณาป้อนอีเมล์").email("อีเมล์ไม่ถูกต้อง"),
    address: Yup.string().required("กรุณาป้อนที่อยู่"),
    city: Yup.string().required("กรุณาป้อนจังหวัด"),
    postcode: Yup.string().required("กรุณาป้อนรหัสไปรษณีย์"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    var memId = localStorage.getItem("id");
    var user = localStorage.getItem("fullname");
    var email = localStorage.getItem("email");
    console.log("user: ", user);
    setAuth(user);
    setEmail(email);
    setUserId(memId);

    /*     if (user === null) {
      navigate("/login");
    } */
  }, []);

  const handleOnSubmit = (data) => {
    axios
      .post(`${ordersUrl}saveorder.php`, {
        memberId: userId != "" ? userId : "0",
        firstname: data.firstName,
        lastname: data.lastName,
        phone: data.mobile,
        email: data.email,
        address: data.address,
        city: data.city,
        postcode: data.postcode,
        note: note,
        cart: cartItems,
        total: totalAmount,
        itemAmount: totalQTY,
      })
      .then(function (response) {
        console.log(response.data);

        navigate(`/paidbill/${response.data.id}`);
      });
  };

  function onSubmit(data) {
    // display form data on success
    handleOnSubmit(data);
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
    return false;
  }
  // console.log(cartItems)

  useEffect(() => {
    dispatch(setGetTotals());
  }, [cartItems, dispatch]);

  const onCheckout = () => {
    navigate("/confirmcheckout");
  };
  const NumberFormatter = (value, decimal) => {
    return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
      "en-US",
      {
        useGrouping: true,
      }
    );
  };
  return (
    <div className="w-full md:py-20">
      <div>
        {/* HEADING AND PARAGRAPH START */}
        <div className="text-center max-w-[800px] mx-auto mt-8 md:mt-0">
          <div className="text-[28px] md:text-[34px] mb-5 font-semibold leading-tight">
            บันทึกคำสั่งซื้อ
          </div>
        </div>
        {/* HEADING AND PARAGRAPH END */}
        <div className="flex flex-col lg:flex-row gap-5 py-10">
          {/* CART ITEMS START */}
          <div className="flex-[1]">
            <div className="text-lg font-bold">สรุปคำสั่งซื้อ</div>
            {cartItems.map((item, i) => (
              <div key={i}>
                <div className="flex flex-col md:flex-row item-center justify-between my-4 border-b pb-4">
                  <h1 className="font-bold"> {item?.productName}</h1>
                  <div>
                    ฿{NumberFormatter(item?.price * item?.cartQuantity, 2)}
                  </div>
                  <div className="font-bold">
                    จำนวน: {item?.cartQuantity} รายการ
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* SUMMARY START */}
          <div className="flex-[1]">
            <div className="my-5 rounded-xl">
              <div className="text-lg font-bold mb-4">ข้อมูลจัดส่งสินค้า</div>
              <div className="flex justify-between">
                <div className="uppercase text-md md:text-lg font-medium">
                  ยอดสั่งซื้อ
                </div>
                <div className="text-md md:text-lg font-medium">
                  {/* &#8377;{subTotal} */}฿
                  {totalAmount != null ? NumberFormatter(totalAmount, 2) : 0}
                </div>
              </div>
              <div className="text-sm md:text-md py-5 border-t mt-5">
                {/* -------- */}
                <div className="flex flex-col md:w-full">
                  <h2 className="mb-4 font-bold md:text-xl text-heading ">
                    ที่อยู่จัดส่งสินค้า (Shipping Address)
                  </h2>
                  <form
                    className="w-full mx-auto"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div>
                      <div className="space-x-0 lg:flex lg:space-x-4">
                        <div className="w-full lg:w-1/2 mb-4">
                          <label className="block mb-3 text-sm font-semibold">
                            ชื่อจริง (First Name)
                          </label>
                          <input
                            type="text"
                            className="w-full py-2 text-black border border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                            name="firstName"
                            {...register("firstName", {
                              required: true,
                            })}
                          />
                          {errors.firstName && (
                            <div className="my-3 text-normal text-red-500 text-xs italic">
                              {errors.firstName?.message}
                            </div>
                          )}
                        </div>
                        <div className="w-full lg:w-1/2 ">
                          <label className="block mb-3 text-sm font-semibold">
                            นามสกุล (Last Name)
                          </label>
                          <input
                            type="text"
                            className="w-full py-2 text-black border border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                            name="lastName"
                            {...register("lastName", {
                              required: true,
                            })}
                          />
                          {errors.lastName && (
                            <div className="my-3 text-normal text-red-500 text-xs italic">
                              {errors.lastName?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="w-full">
                          <label className="block mb-3 text-sm font-semibold">
                            โทรศัพท์ (Mobile)
                          </label>
                          <input
                            type="tel"
                            className="w-full py-2 text-black border border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                            {...register("mobile", {
                              required: true,
                            })}
                          />
                          {errors.mobile && (
                            <div className="my-3 text-normal text-red-500 text-xs italic">
                              {errors.mobile?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="w-full">
                          <label className="block mb-3  text-sm font-semibold">
                            อีเมล์ (Email)
                          </label>
                          <input
                            name="email"
                            type="email"
                            className="w-full py-2 border text-black border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                            {...register("email", {
                              required: true,
                            })}
                          />
                          {errors.email && (
                            <div className="my-3 text-normal text-red-500 text-xs italic">
                              {errors.email?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="w-full">
                          <label className="block mb-3 text-sm font-semibold">
                            ที่อยู่ ( Address)
                          </label>
                          <textarea
                            className="w-full py-2 border text-black border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                            name="address"
                            cols={20}
                            rows={4}
                            {...register("address", {
                              required: true,
                            })}
                          />
                          {errors.address && (
                            <div className="my-3 text-normal text-red-500 text-xs italic">
                              {errors.address?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="space-x-0 lg:flex lg:space-x-4">
                        <div className="w-full lg:w-1/2">
                          <label className="block mb-3 text-sm font-semibold">
                            จังหวัด ( City)
                          </label>
                          <input
                            name="city"
                            type="text"
                            className="w-full py-2 text-black border border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                            {...register("city", {
                              required: true,
                            })}
                          />
                          {errors.city && (
                            <div className="my-3 text-normal text-red-500 text-xs italic">
                              {errors.city?.message}
                            </div>
                          )}
                        </div>
                        <div className="w-full lg:w-1/2">
                          <label className="block  mb-3 text-sm font-semibold">
                            รหัสไปรษณีย์ (Postcode)
                          </label>
                          <input
                            name="postcode"
                            type="text"
                            className="w-full py-2 text-black border border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                            {...register("postcode", {
                              required: true,
                            })}
                          />
                          {errors.postcode && (
                            <div className="my-3 text-normal text-red-500 text-xs italic">
                              {errors.postcode?.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="relative pt-3 xl:pt-6">
                        <label className="block mb-3 text-sm font-semibold">
                          เพิ่มเติม (Notes (Optional))
                        </label>
                        <textarea
                          name="note"
                          className="w-full py-2 text-black border border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                          rows={4}
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                        />
                      </div>
                      <div className="mt-4">
                        <button className="btn btn-accent w-full">
                          บันทึกคำสั่งซื้อ
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* -------- */}
              </div>
            </div>

            {/* BUTTON START */}
            {/*             <div className="flex justify-end">
              <button
                className="w-1/2 py-4 rounded-full bg-black text-white text-lg font-medium transition-transform active:scale-95 mb-3 hover:opacity-75 flex items-center gap-2 justify-center"
                onClick={() => onCheckout()}
              >
                บันทึกคำสั่งซื้อ
              </button>
            </div> */}

            {/* BUTTON END */}
          </div>
          {/* SUMMARY END */}
        </div>
      </div>
    </div>
  );
};

export default ConfirmCheckout;
