import React from "react";
import { Link } from "react-router-dom";
import { sponsorUrl, sponsorFileUrl } from "../utils/baseUrl";
import { FiArrowRight } from "react-icons/fi";
//RelatedSponsor
const SponsorCard = ({ data }) => {
  const { id, title, photo } = data;
  // `${title}`  <Link to={{ pathname: "https://herewecode.io/" }} target="_blank">Click to open HereWeCode (new tab)</Link>
  //href="https://herewecode.io/" target="_blank"
  return (
    <>
      <a
        href={`${title}`}
        target="_blank"
        className="overflow-hidden relative rounded-md shadow-lg hover:shadow transition-all duration-300 cursor-pointer"
      >
        <img
          src={`${sponsorFileUrl}/thumbnail/${photo}`}
          alt="logo"
          className="w-full"
        />
        <div className="p-2 w-full flex justify-end bg-zinc-900/50 text-black/[0.5] rounded-md my-2 absolute bottom-0 left-0">
          <h2 className="text-base font-semibold text-white flex items-center">
            อ่านเพิ่มเติม <FiArrowRight size={22} />
          </h2>
        </div>
      </a>
    </>
  );
};

export default SponsorCard;
