import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import MainLayout from "./template/MainLayout";

import {
  Home,
  About,
  ContactUs,
  ProductDetail,
  ProjectDetail,
  DonateByTax,
  DonateNotTax,
  SignIn,
  SignUp,
  Cart,
  Products,
  Projects,
  Donates,
  ConfirmCheckout,
  SaveDonate,
  ViewBill,
  Blog,
  Online,
  History,
  PaidBill,
} from "./pages";

import {
  AboutHistory,
  AboutUnit,
  ProjectPortfolio,
  ProjectWork,
  ProjectBOD,
  ProjectFv,
  ProjectTD,
} from "./components";
const Layout = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/history",
        element: <AboutHistory />,
      },
      {
        path: "/company",
        element: <AboutUnit />,
      },
      /*       {
        path: "/about",
        element: <About />,
        children: [
          {
            path: "/abouthistory",
            element: <AboutHistory />,
          },
          {
            path: "/aboutunit",
            element: <AboutUnit />,
          },
        ],
      }, */
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/product/:id",
        element: <ProductDetail />,
      },
      {
        path: "/savedonate/:id",
        element: <SaveDonate />,
      },
      {
        path: "/bill/:id/:soundfile",
        element: <ViewBill />,
      },
      {
        path: "/paidbill/:id",
        element: <PaidBill />,
      },
      {
        path: "/project/:id",
        element: <ProjectDetail />,
      },
      {
        path: "/products",
        element: <Products />,
      },
      {
        path: "/projectwork",
        element: <ProjectWork />,
      },
      {
        path: "/aboutbod",
        element: <ProjectBOD />,
      },
      {
        path: "/aboutbfv",
        element: <ProjectFv />,
      },
      {
        path: "/abouttd",
        element: <ProjectTD />,
      },
      {
        path: "/projectportfolio",
        element: <ProjectPortfolio />,
      },
      {
        path: "/projects",
        element: <Projects />,
      },
      {
        path: "/confirmcheckout",
        element: <ConfirmCheckout />,
      },
      {
        path: "/donates",
        element: <Donates />,
      },
      {
        path: "/donatenottax/:id",
        element: <DonateNotTax />,
      },
      {
        path: "/donatewithtax/:id",
        element: <DonateByTax />,
      },
      {
        path: "/signin",
        element: <SignIn />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
      {
        path: "/contact",
        element: <ContactUs />,
      },
      {
        path: "/blogs",
        element: <Blog />,
      },
      {
        path: "/online",
        element: <Online />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
