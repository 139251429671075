import React from "react";
import { Link } from "react-router-dom";
import { proUrl, imgProUrl } from "../utils/baseUrl";

const ProductCard = ({ data }) => {
  console.log("Data: ", data);
  const { id, productName, productDetail, price, stock, picture } = data;
  const NumberFormatter = (value, decimal) => {
    return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
      "en-IN",
      {
        useGrouping: true,
      }
    );
  };
  //transform overflow-hidden bg-white duration-200 hover:scale-105 cursor-pointer
  return (
    <>
      <Link
        to={`/product/${id}`}
        className="overflow-hidden relative rounded-md shadow-lg hover:shadow transition-all duration-300 cursor-pointer"
      >
        <img
          src={`${imgProUrl}/thumbnail/${picture}`}
          alt={productName}
          className="object-cover w-full rounded-md"
        />
        <div className="p-2 w-full bg-zinc-900/50 text-black/[0.5] rounded-md my-2 absolute bottom-0 left-0">
          <h2 className="text-base font-medium  text-white">{productName}</h2>
          <div className="flex items-center text-black/[0.5]">
            <p className="mr-2 text-lg font-semibold  text-white">
              ฿{NumberFormatter(price, 2)}
            </p>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ProductCard;
