import React, { useState, useEffect } from "react";
import { aboutUrl, contactUrl, slideUrl, imgUrl } from "../utils/baseUrl";
import { Link, Outlet, useLocation } from "react-router-dom";
import axios from "axios";
import { FaArrowRight } from "react-icons/fa";

const AboutHistory = () => {
  /*   const location = useLocation();
  console.log("pathname", location.pathname);
  const path = location.pathname; */
  const [aboutData, setAboutData] = useState([]);
  function readabout() {
    axios.get(`${aboutUrl}abouthistory.php`).then(function (response) {
      console.log(response.data);
      setAboutData(response.data);
    });
  }

  useEffect(() => {
    readabout();
  }, []);
  return (
    <div className="md:pb-[8rem]">
      {aboutData?.map((item, i) => (
        <div className="flex flex-col my-10" key={i}>
          <div className="sm:text-3xl text-xl font-bold mb-8">
            {item?.title}
          </div>
          <div className="md:row-start-1 shadow-lg hover:shadow transition-all duration-300 cursor-pointer">
            <img
              className="object-cover w-full rounded-md"
              src={`${imgUrl}${item?.photo}`}
              alt=""
            />
          </div>
          <div className="flex flex-col justify-center items-start gap-6 mt-8">
            <p
              className="text-sm font-normal opacity-70"
              dangerouslySetInnerHTML={{ __html: item?.detail }}
            ></p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AboutHistory;
