import React, { useContext, useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  HeroBanner,
  Wrapper,
  ProductDetailsCarousel,
  ProjectDetailsCarousel,
  CtaSection,
  RelatedProducts,
  RelatedProject,
} from "../components";
// import { proUrl, imgProUrl } from "../utils/baseUrl";
import { proUrl, donateUrl, catUrl, pjUrl, imgPjUrl } from "../utils/baseUrl";
import { IoMdHeartEmpty } from "react-icons/io";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProjectDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState(null);
  const [gallerys, setGallerys] = useState([]);

  function readProject(id) {
    axios.get(`${pjUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setProject(response.data);
    });
  }
  function readAllProject() {
    axios.get(`${pjUrl}read.php`).then(function (response) {
      console.log(response.data);
      setProjects(response.data);
    });
  }

  function readgallery(id) {
    axios.get(`${pjUrl}readgallery.php/${id}`).then(function (response) {
      console.log(response.data);
      setGallerys(response.data);
    });
  }

  useEffect(() => {
    readgallery(id);
    // readproduct(id);
    readProject(id);
    readAllProject();
  }, []);

  const notify = () => {
    toast.success("Success. Check your cart!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const NumberFormatter = (value, decimal) => {
    //new Intl.NumberFormat('en-US').format(price)
    return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
      "en-US",
      {
        useGrouping: true,
      }
    );
  };

  function numberWithCommas(num) {
    let total = num.toString();
    return total.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  /*   function numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } */

  //let n = numberWithCommas(234234.555);
  //console.log(n); // "234,234.555"

  const onDonateNotTax = (id) => {
    navigate(`/donatenottax/${id}`);
  };
  const onDonateWithTax = (id) => {
    navigate(`/donatewithtax/${id}`);
  };
  return (
    <div className="w-full md:py-20">
      <ToastContainer />
      <div>
        <div className="flex flex-col lg:flex-row  gap-[50px] lg:gap-[100px]">
          {/* left column start md:px-10*/}
          <div className="w-full md:w-auto flex-[1.5] mx-auto lg:mx-0">
            {gallerys.length > 0 ? (
              <>
                <ProjectDetailsCarousel images={gallerys} />
              </>
            ) : (
              <>
                <img
                  src={`${imgPjUrl}/${project?.picture}`}
                  alt="logo"
                  className="object-cover w-full rounded-md"
                />
              </>
            )}
          </div>
          {/* left column end */}

          {/* right column start */}
          <div className="flex-[1] py-3">
            {/* PRODUCT TITLE */}
            <div className="text-2xl font-semibold mb-2 leading-tight">
              {project?.projectName}
            </div>
            {/* PRODUCT PRICE */}
            <div className="flex items-center">
              <p className="mr-2 mb-4 text-lg font-semibold">
                {/* มูลค่าโครงการ : {numberWithCommas(project?.total)} บาท */}
                มูลค่าโครงการ : {NumberFormatter(project?.total)} บาท
              </p>
            </div>
            {/* PRODUCT SIZE RANGE END */}

            {/* ADD TO CART BUTTON START w-full  py-4 rounded-full bg-black text-white text-lg font-medium transition-transform active:scale-95 mb-3 hover:opacity-75*/}
            <button
              className="btn btn-accent w-full"
              onClick={() => onDonateNotTax(id)}
            >
              บริจาคตอนนี้
            </button>
            {/* ADD TO CART BUTTON END */}

            {/* WHISHLIST BUTTON START */}
            <button
              onClick={() => onDonateWithTax(id)}
              className="btn btn-primary w-full my-4"
            >
              บริจาคลดหย่อนภาษี
              {/* <IoMdHeartEmpty size={20} /> */}
            </button>
            {/* WHISHLIST BUTTON END */}

            <div>
              <div className="text-lg font-bold mb-5">
                รายละเอียดโครงการบริจาค
              </div>
              {/*              <div className="markdown text-md mb-5">
                <ReactMarkdown>{product?.productDetail}</ReactMarkdown>
              </div> */}
              <p
                className="markdown text-md mb-5"
                dangerouslySetInnerHTML={{ __html: project?.detail }}
              ></p>
            </div>
          </div>
          {/* right column end */}
        </div>
        {/* <RelatedProject projects={projects} title={"โครงการบริจาค"} /> */}
        {/* <RelatedProject projects={projects} title={" "} /> */}
      </div>
    </div>
  );
};

export default ProjectDetail;
