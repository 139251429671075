import React, { useContext, useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  HeroBanner,
  Wrapper,
  ProductDetailsCarousel,
  ProjectDetailsCarousel,
  CtaSection,
  RelatedProducts,
  RelatedProject,
  Title,
} from "../components";
// import { proUrl, imgProUrl } from "../utils/baseUrl";
import {
  proUrl,
  donateUrl,
  catUrl,
  pjUrl,
  imgPjUrl,
  bankUrl,
} from "../utils/baseUrl";
import { IoMdHeartEmpty } from "react-icons/io";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QRCode from "qrcode";
import qrImg from "../assets/qrcode.jpg";

const DonateNotTax = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [bank, setBank] = useState(null);
  const [url, setUrl] = useState("");
  const [qr, setQr] = useState("");
  const [qrShow, setQrShow] = useState(false);

  const GenerateQRCode = (bnourl) => {
    QRCode.toDataURL(
      bnourl,
      {
        width: 800,
        margin: 2,
        color: {
          dark: "#335383FF",
          light: "#EEEEEEFF",
        },
      },
      (err, url) => {
        if (err) return console.error(err);

        console.log(url);
        setQr(url);
      }
    );
  };
  /*   dark: "#335383FF",
  light: "#EEEEEEFF", */
  function readProject(id) {
    axios.get(`${pjUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setProject(response.data);
    });
  }

  function readbank() {
    axios.get(`${bankUrl}readonebank.php`).then(function (response) {
      console.log(response.data);
      setUrl(response?.data?.bankno);
      setBank(response.data);
      GenerateQRCode(response?.data?.bankno);
    });
  }

  useEffect(() => {
    readProject(id);
    readbank();
  }, []);

  const onSaveDonate = (id) => {
    navigate(`/savedonate/${id}`);
  };
  return (
    <div className="w-full md:py-20">
      <div className="mb-16">
        {/* HEADING AND PARAGRAPH START text-[28px] md:text-[34px] */}
        <div className="text-center max-w-[800px] mx-auto mt-8 md:mt-0">
          {/*           <div className="mb-5 font-semibold leading-tight">
            {project?.projectName}
          </div> */}
          {/*  */}
          <div className="w-full md:w-auto flex-[1.5] max-w-[500px] lg:max-w-full mx-auto lg:mx-0">
            <Title title={project?.projectName} />
          </div>
          <div className="mb-6">เลขบัญชี: {bank?.bankno}</div>
          {/* qrImg w-[150px] h-[150px] */}
          <div>
            <div className="flex justify-center">
              <img src={qrImg} className=" w-[350px] object-cover " />
            </div>

            <div className="flex flex-col items-center gap-6 justify-center mt-6">
              <a href={qr} download="qrcode.png">
                Download
              </a>
              <button
                className="btn btn-accent"
                onClick={() => onSaveDonate(id)}
              >
                ลงชื่อทำเนียบผู้บริจาค
              </button>
            </div>
          </div>
          {/*           <div>
            {qr ? (
              <>
                <div className="flex justify-center">
                  <img src={qr} className="w-[150px] h-[150px] " />
                </div>

                <div className="flex flex-col items-center gap-6 justify-center mt-6">
                  <a href={qr} download="qrcode.png">
                    Download
                  </a>
                  <button
                    className="btn btn-accent"
                    onClick={() => onSaveDonate(id)}
                  >
                    ลงชื่อทำเนียบผู้บริจาค
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
          </div> */}
          {/* <div className="flex-[1] py-3"></div> w-full py-4 px-1 rounded-full bg-accent text-lg font-medium transition-transform active:scale-95 mb-3 hover:opacity-75 md:w-1/2 */}
        </div>
      </div>
    </div>
  );
};

export default DonateNotTax;
