import React, { useState, useEffect } from "react";

//create context
//``
//proUrl ,imgProUrl, imgBlogUrl
import { aboutCompanyUrl, imgUrl } from "../utils/baseUrl";

import axios from "axios";

//imgCompanyUrl,imgBankUrl
const Online = () => {
  const [blogs, setBlogs] = useState([]);
  function readblog() {
    axios.get(`${aboutCompanyUrl}read.php`).then(function (response) {
      console.log(response.data);
      setBlogs(response.data);
    });
  }

  useEffect(() => {
    readblog();
  }, []);
  return (
    <div className="md:pb-[8rem]">
      {blogs?.map((item, i) => (
        <div className="flex flex-col my-10" key={i}>
          <div className="md:row-start-1 shadow-lg hover:shadow transition-all duration-300 cursor-pointer">
            <img
              className="w-full h-[300px] object-cover rounded-md"
              src={`${imgUrl}${item?.photo}`}
              alt=""
            />
          </div>
          <div className="flex flex-col justify-center items-start gap-6">
            <div className="sm:text-3xl text-xl font-bold mt-8">
              {item?.title}
            </div>
            <p
              className="text-sm opacity-70"
              dangerouslySetInnerHTML={{ __html: item?.detail }}
            ></p>
            {/*           <div className="btn">
        <a href="" className="text-white text-[0.85rem]">
          Explore more
        </a>
        <FaArrowRight className="text-white" />
      </div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Online;
