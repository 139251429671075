import React from "react";
//text-[28px] mb-4 md:text-[34px] font-semibold
//
const Title = ({ title }) => {
  return (
    <>
      <h1 className="text-xl md:text-3xl font-bold mb-5">{title}</h1>
    </>
  );
};

export default Title;
