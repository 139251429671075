import React, { useContext, useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  HeroBanner,
  Wrapper,
  ProductDetailsCarousel,
  ProjectDetailsCarousel,
  CtaSection,
  RelatedProducts,
  RelatedProject,
  Title,
} from "../components";
// import { proUrl, imgProUrl } from "../utils/baseUrl";
import {
  proUrl,
  donateUrl,
  catUrl,
  pjUrl,
  imgPjUrl,
  bankUrl,
} from "../utils/baseUrl";
import { IoMdHeartEmpty } from "react-icons/io";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const SaveDonate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [bank, setBank] = useState(null);
  const [selectedFile, setSectedFile] = useState(null);
  const [selectedProfile, setSectedProfile] = useState(null);
  const [auth, setAuth] = useState(null);
  const [email, setEmail] = useState(null);
  const [userId, setUserId] = useState("");

  function readProject(id) {
    axios.get(`${pjUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setProject(response.data);
    });
  }

  /*   useEffect(() => {
    readProject(id);
  }, []); */

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  // form validation rules
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("กรุณาป้อนชื่อ-สกุล"),
    /*     mobile: Yup.string()
      .required("กรุณาป้อนโทรศัพท์")
      .matches(phoneRegExp, "กรุณาป้อนโทรศัพท์ไม่ถูกต้อง"), */
    amount: Yup.string().required("กรุณาป้อนยอดเงินบริจาค"),
    displayName: Yup.string().required("กรุณาระบุประสงค์ออกนาม"),
    displayAmount: Yup.string().required("กรุณาระบุโชว์ยอดบริจาค"),
    acceptTerms: Yup.bool().oneOf([true], "ต้องยอมรับเงื่อนไขการบริจาค"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  function onSubmit(data) {
    // display form data on success
    handleOnSubmit(data);
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
  }
  useEffect(() => {
    var memId = localStorage.getItem("id");
    var user = localStorage.getItem("fullname");
    var email = localStorage.getItem("email");
    console.log("user: ", user);
    setAuth(user);
    setEmail(email);
    setUserId(memId);
    readProject(id);
    /*     if (user === null) {
      navigate("/login");
    } */
  }, []);
  const handleOnChange = (e) => {
    console.log("Slip----------");
    console.log(e.target.files[0]);
    console.log("/Slip----------");
    setSectedFile(e.target.files[0]);
  };
  const handleProfileOnChange = (e) => {
    console.log("Profile----------");
    console.log(e.target.files[0]);
    console.log("/Profile----------");
    setSectedProfile(e.target.files[0]);
  };

  //    slip: Yup.string().required("กรุณาอัพโหลดรูปสลิบโอนเงิน"),
  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("profileData", selectedProfile);
    formData.append("fullName", data.fullName);
    formData.append("phone", data.mobile);
    formData.append("isAmount", data.displayAmount);
    formData.append("isName", data.displayName);
    formData.append("total", data.amount);
    formData.append("memberId", userId);
    formData.append("projectId", id);
    axios.post(`${donateUrl}add.php`, formData).then(function (response) {
      console.log(response.data);
      console.log(response.data.id);

      if (Number(response.data.id) > 0) {
        const donatid = Number(response.data.id);
        navigate(`/bill/${donatid}/${project?.soundFile}`);
      }
    });
  };

  return (
    <div className="w-full md:py-20">
      <div className="mb-16">
        {/* HEADING AND PARAGRAPH START text-[28px] md:text-[34px] */}
        <div className="text-center max-w-[800px] mx-auto mt-8 md:mt-0">
          {/*           <div className="mb-5 font-semibold leading-tight">
          {project?.projectName}
        </div> */}
          {/*  */}
          <div className="w-full md:w-auto flex-[1.5] max-w-[500px] lg:max-w-full mx-auto lg:mx-0">
            <Title title={project?.projectName} />
          </div>

          <div className="">
            <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
              <div className="grid gap-4 gap-y-2 text-sm grid-cols-1">
                <div className="md:col-span-5">
                  <label className="mb-2 block text-start">ชื่อ-สกุล</label>
                  <input
                    type="text"
                    className="w-full text-black py-3 border border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                    placeholder=""
                    {...register("fullName")}
                    name="fullName"
                  />
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    {errors.fullName?.message}
                  </div>
                </div>

                <div className="md:col-span-2 mt-4">
                  <label className="mb-2 block text-start">เบอรโทร</label>
                  <input
                    type="tel"
                    className="w-full text-black py-3 border border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                    {...register("mobile")}
                    name="mobile"
                  />
                  {/*                   <div className="my-3 text-normal text-red-500 text-xs italic">
                    {errors.mobile?.message}
                  </div> */}
                </div>

                <div className="md:col-span-3 mt-4">
                  <label className="mb-2 block text-start">รูปโปรไฟล์</label>
                  <input
                    type="file"
                    className="w-full py-3  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                    onChange={handleProfileOnChange}
                  />
                  {/*                             <div className="my-3 text-normal text-red-500 text-xs italic">
                              {errors.slip?.message}
                            </div> */}
                </div>
                <div className="md:col-span-3 mt-4">
                  <label className="mb-2 block text-start">อัพสลิป</label>
                  <input
                    type="file"
                    className="w-full py-3  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                    onChange={handleOnChange}
                  />
                  {/*                             <div className="my-3 text-normal text-red-500 text-xs italic">
                              {errors.slip?.message}
                            </div> */}
                </div>
                <div className="md:col-span-2 mt-4">
                  <label className="mb-2 block text-start">ยอดบริจาค</label>
                  <input
                    type="number"
                    className="w-full text-black py-3 border border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                    placeholder=""
                    {...register("amount")}
                    name="amount"
                  />
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    {errors.amount?.message}
                  </div>
                </div>
                <div className="md:col-span-2 mt-4">
                  <label className="mb-2 block text-start">
                    ประสงค์ออกนาม / ไม่ประสงค์ออกนาม
                  </label>
                  <div className="">
                    <select
                      {...register("displayName")}
                      name="displayName"
                      className="w-full text-black py-3 border border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                    >
                      <option value=""></option>
                      <option value="1">ประสงค์ออกนาม</option>
                      <option value="0">ไม่ประสงค์ออกนาม</option>
                    </select>
                    <div className="my-3 text-normal text-red-500 text-xs italic">
                      {errors.displayName?.message}
                    </div>
                  </div>
                </div>
                <div className="md:col-span-3 mt-4">
                  <label className="mb-2 block text-start">
                    ประสงค์ โชว์ยอดบริจาค / ไม่ประสงค์ โชว์ยอดบริจาค
                  </label>
                  <div className="">
                    <select
                      {...register("displayAmount")}
                      name="displayAmount"
                      className="w-full text-black py-3 border border-slate-200 rounded-sm px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                    >
                      <option value=""></option>
                      <option value="1">ประสงค์ โชว์ยอดบริจาค</option>
                      <option value="0">ไม่ประสงค์ โชว์ยอดบริจาค</option>
                    </select>
                    <div className="my-3 text-normal text-red-500 text-xs italic">
                      {errors.displayAmount?.message}
                    </div>
                  </div>
                </div>

                <div className="md:col-span-5 my-4">
                  <div className="inline-flex items-center">
                    <input
                      name="acceptTerms"
                      type="checkbox"
                      {...register("acceptTerms")}
                      id="acceptTerms"
                      className="mr-2 w-6 h-6 text-sm text-green-600 border-0 rounded-md focus:ring-0"
                    />
                    <label
                      htmlFor="acceptTerms"
                      className="inline-flex items-center"
                    >
                      ข้าพเจ้ายินยอมให้เปิดเผยข้อมูลส่วนบุคคลที่ได้ให้ไว้แก่มูลนิธิผ่านเว็บไซต์ปันบุญแก่หน่วยรับบริจาค
                      เพื่อวัตถุประสงค์ในการประชาสัมพันธ์โครงการบริจาค หรือ
                      กิจกรรมของหน่วยรับบริจาคเอง
                    </label>
                  </div>
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    {errors.acceptTerms?.message}
                  </div>
                </div>

                <div className="md:col-span-5 text-right">
                  <div className="md:inline-flex items-end">
                    <button type="submit" className="btn btn-accent w-full">
                      ยืนยันข้อมูลบริจาค
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* <div className="flex-[1] py-3"></div> */}
        </div>
      </div>
    </div>
  );
};

export default SaveDonate;
