import React from "react";
import { proUrl, imgProUrl } from "../utils/baseUrl";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const ProductDetailsCarousel = ({ images }) => {
  return (
    <div className="text-white text-[20px] w-full max-w-[1360px] mx-auto sticky top-[50px]">
      <Carousel
        infiniteLoop={true}
        showIndicators={false}
        showStatus={false}
        // autoPlay={true}
        thumbWidth={60}
        className="productCarousel"
      >
        {images?.map((item, i) => (
          <img
            className="object-cover w-full  h-[400px] rounded-md shadow-2xl"
            // className="object-cover h-[150px] w-full rounded-md shadow-2xl"
            src={`${imgProUrl}${item.img}`}
            alt=""
            key={i}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default ProductDetailsCarousel;
